.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right:none !important;
}
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@font-face {
  font-family:"Chancery Bold";
  src: url(./styles//fonts/chancerybold.ttf);
}

@font-face {
  font-family:"Chancery Regular";
  src: url(./styles//fonts//chanceryregular.ttf);
}
@font-face {
  font-family:"Journal";
  src: url(./styles//fonts//journal.ttf);
}
@font-face {
  font-family:"Aerolite";
  src: url(./styles//fonts//AeroliteCPone.otf);
}

@font-face {
  font-family:"Aerolite Two";
  src: url(./styles/fonts/AeroliteCPtwo.otf);
}

$priamryColor: #0BBCEF;
$darkBlueColor: #48507A;
$darkLightBlueColor: #555C83;
$lightGreyColor: #8492A6;
$lightGreyColor1: #848895;
$mediumGreyColor: #666766;
$borderColor: #F1F1F1;
$lightBorderColor: #E7E7E7;
.logo-outer{
  height: 100px;
}

.logo-header-outer{
  width: 100%;
  height: 50px;
  position: fixed;
  margin-top: 20px;
  margin-left: 5px;
  top: 10px;
}

.logo-header-inner{
  height: 35px;
  width: 140px;
  position: absolute;
  left: 0;
}

input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.activetext p{ 
  color: #00b5e2 !important;
 }

h1,
h3,
h5 {
  text-align: center;
  cursor: pointer;
}

h1 {
  font-size: 36px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
  font-weight: normal;
}

label {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
}

.preview-header{
  margin-bottom: 15px;
  display: table;
  font-size: 20px;
  color : #00b5e2;
  font-family: 'Zilla Slab', serif;
  font-weight: 600;
  font-size: 25px;
}

.preview-image{
  width: 100%;
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: 0 50px;
  margin-bottom: 25px;
  border: 1px solid #6d6e71;
  padding: 5px 15px;
}

.spinner-grow{
  position: absolute;
  top: 50%;
  left: 50%;
}
.pre-loader{
  float: left;
  width: 250px;
  height: 175px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.preview-img{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border: 2px solid rgb(204, 204, 204);
  padding: 10px 0;
  margin: 10px 0 0;
  position: relative;
}

.preview-img::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  border: 1px dashed #00b5e2;
  right: -30px;
}

.preview-image-inner img {
  width: 400px;
  background-color: transparent;
  height: 300px;
  object-fit: contain;
  pointer-events: none;
}

.preview-edit-icon{
  text-align: center;
}

.preview-edit-icon svg{
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: #6d6e71;;
}

.preview-content h4{
  color: #00b5e2;
  text-align: unset;
  font-size: 20px;
  margin: 0;
  font-weight: 600;
}

.preview-content p,
.preview-content input{
  color: #6d6e71;
}

.preview-qty input{
  font-weight: bolder;
}

.preview-content-tips h4{
  color: #00b5e2;
  text-align: unset;
  font-size: 23px;
  font-family: 'Zilla Slab', serif;
  font-weight: 600;
}

.preview-content-tips p{
  color: #6d6e71;
}

.preview-qty-price-outer{
  display: grid;
  grid-template-columns: 15% 10%;
  grid-gap: 85px;
  align-items: center;
}

.preview-qty,
.preview-price{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
}

.preview-qty p{
  margin: 0;
}

.preview-price p{
  margin: 2px 0;
  font-weight: bolder;
  color: #6d6e71;
  font-size: 17px;
}

.preview-wizard,
.preview-wizard-receipt{
  width: 203px;
  height: 75px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background : #ebebeb;
  color: #fff;
  position: relative;
}

.preview-wizard-active{
  background: #d1df47;
}

.preview-wizard p,
.preview-wizard-receipt p{
  margin : 0;
  font-weight: bolder;
}

.preview-wizard-outer{
  display: flex;
  grid-gap: 20px;
}

.preview-wizard::before {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 8px 9px 8px;
  border-color: transparent transparent #6c6c6b transparent;
  transform: rotate(90deg);
  position: absolute;
  right: -19px;
  top: 33px;
}

.preview-title-outer h5{
  text-align: unset;
  color: #00b5e2;
  cursor: unset;
  font-family: 'Zilla Slab', serif;
  font-weight: 600;
  font-size: 23px;
}

#previewModal .modal-body{
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 0 22px 20px;
}

.preview-content{
  margin: 10px 0;
}

.preview-content button {
  padding: 7px 10px;
  width: 200px;
  position: relative;
  left: 50%;
  margin-top: 19%;
  background-color: #6b6f72;
  font-weight: 600;
  transform: translateX(-50%);
}

.preview-content button:focus {
  box-shadow: none;
}

.preview-content button:focus-visible {
  outline: 0;
}

.btn-edit{
  background-color: #0088a9;
  border-radius: 0;
  color: #fff;
}

.btn-edit:hover{
  color: #fff;
}

.modal-backdrop.show{
  opacity: 0.9 !important;
}

.main-container-inner{
  height: calc(100vh - 160px);
  width: 100%;
  background: rgb(240, 240, 240);
}

.spinner-overlay {
  width: 100%;
  height: calc(100vh - 100px);
  display: grid;
}

.spinner-overlay-cart{
  position: fixed !important;
  width: 100%;
  height: calc(100vh - 70px);
  top: 101px;
  max-width: 100%;
  left: 0;
}

.spinner-overlay-cart-loader{
  z-index: 9999;
}

.btn-undo svg,
.btn-redo svg{
  color: #000;
  font-size: 26px;
}

.shape-gallery{
  height: 410px;
}

.tab-list{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 161px);
}

.layers-icon{
  color: #6c757d;
  width: 30px;
  height: 30px;
}

#previewModal.modal-xl{
  max-width: 100%;
  height: 100%;
}

#previewModal .modal-content{
  height: 100%;
}

#previewModal.modal-dialog{
  margin: 0;
}

#previewModal .modal-body{
  overflow-y: auto;
  overflow-x: hidden;
}

.preview-qty input {
  width: 70px;
  padding: 10px;
  text-align: center;
  height: 38px;
  border: 1px solid #6b6f72 !important;
  border-radius: 0;
}

.preview-qty input:focus-visible {
  outline: 0;
}

#previewModal .modal-header{
  padding: 33px 5px;
  margin-bottom: 15px;
  border: 1px solid grey;
}

.temp-canvas{
  margin-left: 10%;
}
@media (max-width: 1367px) {
  .toolbar-container .more-options{
    margin-right: 10px !important;
  }

  .main-container .largedevice .rwt__tablist[aria-orientation=vertical]{
    padding: 0 !important;
  }

  .left-side-panel .side-panel-container{
    margin-top: 15px !important;
  }

  .image-gallery,
  .layers-gallery
  .clip-gallery,
  .layers-gallery {
    min-height: 450px !important;
    max-height: 450px !important;
  }

  .zoom-range{
    top: calc(90vh - 43px) !important;
  }

  .toolbar-container{
    padding: 10px 8px !important;
  }
}
@media (max-height: 600px){
  .main-container .largedevice .rwt__tab {
    padding: 0px 2rem !important;
  }

  .left-side-panel .side-panel-container{
    margin-top: 10px !important;
  }

  .image-gallery,
  .layers-gallery {
    min-height: 412px !important;
    max-height: 412px !important;
  }

  .clip-gallery{
    min-height: 420px !important;
    max-height: 420px !important;
  }

  .zoom-range{
    top: calc(90vh - 45px) !important;
  }

  .left-side-panel .side-panel-container .text-editer.text-gallery{
    height: 412px !important;
    overflow: hidden !important;
  }

  .left-side-panel .side-panel-container .img-title{
    margin-bottom: 10px !important;
  }

  .color-gallery{
    height: 420px !important;
    background-color: #fff !important;
  }

  .custom-color{
    padding: 10px 16px 16px 16px !important;
  }

  .color-outer{
    margin-bottom: 0 !important;
    height: 45px;
  }

  .toolbar-container{
    padding: 10px 20px !important;
  }

  .left-side-panel .side-panel-container .dcl-upload-button{
    margin-bottom: 8px !important;
  }

  .dcl-browse-button{
    margin-bottom: 22px !important;
  }

  .clipimage-container-outer{
    grid-gap: 10px !important;
  }
}
@media (max-width: 1025px) {
  .custom-color{
    padding: 16px !important;
  }

  .toolbar-container .colrsec{
    margin-right: 17px !important;
  }
  
  .main-container .largedevice .rwt__tablist[aria-orientation=vertical] {
    padding-top: 12px !important;
  }

  .main-container .largedevice .rwt__tab{
    padding: 5px 2rem !important;
  }

  .left-side-panel .side-panel-container{
    margin-top: 24px !important;
  }

  .zoom-range{
    top: calc(90vh - 53px) !important;
  }

  .toolbar-container{
    padding: 10px 10px !important;
  }

  .zoom-range {
    right: 50px !important;
  }

  .image-gallery,
  .clip-gallery,
  .color-gallery,
  .layers-gallery{
    min-height: 430px !important;
    max-height: 430px !important;
  }

  .clipimage-container-outer{
    height: 215px !important;
  }

  .left-side-panel .side-panel-container .dcl-upload-button{
    margin-bottom: 15px !important;
  }

  .dcl-browse-button{
    margin-bottom: 30px !important;
  }

  .clipimage-container-outer{
    grid-gap: 6px !important;
  }

  .safe-area .dropdown-menu.show{
    transform: translate3d(65px,25.5px,0) !important;
  }
}
/* Firefox-specific CSS */
@-moz-document url-prefix() {
  /* CSS rules here will only apply to Firefox */
  .image-upload-gallery-fox{
    width: 95px !important;
    height: 95px !important;
  }

  .uploads-title{
    margin-bottom: 15px;
  }
  @media screen and (max-height: 600px){
    .left-side-panel .side-panel-container .text-editer.text-gallery{
      height: 412px !important;
      overflow: hidden !important;
    }

    .custom-color{
      padding: 5px 16px 16px 16px !important;
    }

    .color-gallery{
      height: 412px !important;
    }

    .clip-gallery{
      min-height: 410px !important;
      max-height: 410px !important;
    }

    .dcl-browse-button{
      margin-bottom: 10px !important;
    }
  }
}

//footer
.footer-btn{
  background-color: #0088a9;
  border-radius: 0;
}

.continue-btn{
  background: linear-gradient(to bottom, #ffcd5d 0%, #ff9a00 100%) !important;
  border-radius: 0;
}

.footer-btn-icon i{
  margin-right: 5px;
}

.footer-btn-icon svg{
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.footer-btn-continue-icon i{
  margin-left: 5px;
}