
.dropdown-toggle::after {
    display: inline-block;
    width:auto;
    margin-left: 9.150em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.upper-canvas :nth-child(1)
{
    background-color:red;
}
/* .upper-canvas :nth-child(2)
{
    background-color:green;
}  */
.parent {
  display: flex;

  flex-wrap: wrap;
 position:relative;
 margin-left:2px;
}
.angel-row {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}
.angel-row .child {
  width: 15%;
  height: 70px;


  margin-top:20px;
  margin-bottom: 15px;
}
.angel-row .child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.child img:hover {
 border:2px solid #8b8a8a;
 cursor:pointer
}
.current{
  background-color: red;
}
.current1{
  background-color: #fff;
}
.current3{
  background-color: orange;
}
/* .child {
  flex: 1 0 21%;
  position:relative;
  margin:30px 0px 0px -50px;

} */

.category{
  color: #000;

    font-size: 12px;
    /* text-transform: uppercase; */
}
#category{
  color: #000;

    font-size: 12px;
    /* text-transform: uppercase; */
}
#category:hover{
color: #000;
}
 .space {
    padding-top:20px;

}
.dropdown-toggle {

    white-space: nowrap;
    width: 300px;
    font-size: 12px;
}
#uploadedImgDiv{
  padding-bottom: 100px;
    background-color: #fff;
    /* overflow-y: scroll; */
    max-height: 600px;
    height: 190px;
}

.template-hover:hover{
  cursor: pointer;
  background-color: rgb(236,242,238,1);
}
.template-active{
  cursor: pointer;
  background-color: rgb(236,242,238,1);
}

.template-thumbnail {
  width: 40px;
  height: 100px;
}
.template-title {
  font-size: 14px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    /* Remove default styling ul */
  padding-top:20px;
    margin: 0px;
    padding-left: 0;
    list-style-type: none;
  }



  .card {
    /* background: teal; */
    color: white;
    /* padding: 1rem; */
    flex: 0 1 calc(50% - 3rem); /* need to substract the padding and margin */
    margin: 0 0 1rem 20px;
    height:100px;
  }
  .card:hover{
    border:1px solid #767677;
    /* background-color: red; */
    cursor:pointer;

    opacity: 0.3;


  }
  .imtip {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 25px;
    width: 20px;
    border-radius: 15%;
    border: 1px solid black;
    background-color: transparent;
    padding:2px;
  }


  .imtip:hover{
    position: absolute;
    top: 3px;
    right: 3px;
    height: 25px;
    width: 20px;
    border-radius: 15%;
    border:1px solid black;
    background: #f1f1f1 !important;
    cursor: pointer;
  }

  .imtip2{
    position: relative;
    top:-33px;
    right: 3px;
    height: 25px;
    width: 20px;
    border-radius: 15%;
    border: 1px solid black;
    background-color: transparent;
    padding:2px;
  }
  .imtip2:hover{
    position: relative;
    top: 3px;
    right: 3px;
    height: 25px;
    width: 20px;
    border-radius: 15%;
    border:1px solid black;
    background: #f1f1f1 !important;
    cursor: pointer;
  }
  .fronttxt{
    position:"absolute";margin-left:"350px";margin-top:"-50px";color:red;
  }
  .backtxt{
    position:"absolute";margin-left:"350px";margin-top:"-50px";color:red;
  }
  .canvas-title {
    font-weight: bold;
    position: absolute;
    /* margin-left: 45%; */
    text-align:center;
    margin-top: -30px;
    color: rgb(255, 255, 255);
  }
  .image{
    height:100px;
    width:auto;
    border:1px solid #e1e6eb;

  }
  .bookmark{
    height: 500px;
    border:1px solid #e1e6eb;

  }
  .card:nth-child(2n+1) {
    margin-right: 1rem; /* add margin-right to every 2nd item */
  }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00b5e2;
    border-color: #00b5e2;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.btn-primary{
   background-color: #fff;
   color:#000;
   border:1px solid rgb(225, 230, 235);
}
.btn-primary:hover{
    background-color: #fff;
    color:#000;
    border:1px solid rgb(225, 230, 235);
 }
 #search-clipart{
    background-color:#00b5e2;
    height: 32px;
    margin: -1px 0px 0px 0px;
 }
 #printBtn{
  background-color:#00b5e2;
  height: 32px;
  margin: -1px 0px 0px 20%;
}
 #img-wrapper {
    display: flex;
    flex-wrap: wrap;
   }

   #img-wrapper > div {
    flex: 0 1 11%;
   }
   #clipartSearchTerms{
    width:200px;


   }
@media (max-width: 480px) {
    .space {
        padding-top:20px;

    }
    .btn-primary{
        background-color: #fff;
        color:#000;
     }
     .btn-primary:hover{
        background-color: #fff;
        color:#000;
     }
    .dropdown-toggle {
        position:relative;
        white-space: nowrap;
        width: 120px;
        font-size: 10px;
    }
    #modal-content1 {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 0.3rem;
      outline: 0;
      height: 500px;
  }
    .dropdown-toggle::after {
        display: inline-block;

        margin-left: 1.100em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
  }