@import "../App.scss";

.main-container {
  float: left;
  width: 100vw;
  z-index: -1;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .largedevice{
    .rwt__tab[aria-selected='false']:hover
    {
      background-color: transparent !important;
    }

    .rwt__tab[aria-selected='true'] {
      background-color: transparent !important;

    .sidenav {
      color : #00b5e2;
      min-width: 56px;
      float: left;
    }

    .edit-box{
      background: #fff;
      padding: 8px 0 6px 0;
      border: 2px solid #00b5e2;
    }

  }
    .rwt__tablist[aria-orientation="vertical"]{
      padding: 19px 0px 0px 0px !important;
    }
    
    .rwt__tab{
      padding: 8px 2rem !important;
    }
  }

  .edit-box{
    background: #e1e6ea;
    padding: 8px 0 6px 0;
  }

  .sidenav {
    min-width: 56px;
    float: left;
    font-size: 12px;
    color: #697582;
    margin-top: 10px;
  }

  .tabpanel {
    background-color:#F1F1F1;

    .vertical-tabs {
      background-color:#F1F1F1;
      position: relative;

      .edit-box {
        border-bottom: 1px solid $borderColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 86px;

        span {
          color: $lightGreyColor;
          font-size: 10px;
          line-height: 11px;
          margin-top: 10px;
        }
      }
    }

    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
      border-right: none!important;
      height: 100%;
    }

    .rwt__tablist {
      margin-right: 0 !important;
    }

    .rwt__tabpanel {
      padding: 30px 23px;
    }

    .rwt__tab {
      padding: 0 !important;
    }

    .rwt__tab:focus,
    .rwt__tab[aria-selected="false"]:hover {
      background-color: #00b5e2!important;
    }

    .rwt__tab[aria-selected='true'] {
      background-color: #00b5e2!important;
      color:#fff;

      .text-muted{
        color:#fff !important;
      }

      .edit-box span{
        color:#fff;
      }
    }
  }
}
