@import "../App.scss";

.toolbar-container {
  border-style:outset;
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;

  .leftbar,
  .rightbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .toolbar-label {
    color: $darkLightBlueColor;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .leftbar {
    .font-color-container {
      height: 28px;
      border: 2px solid #ECECEC;
      border-radius: 4px;
      padding: 0 5px;
      cursor: pointer;
      margin-right: 20px;
      margin-bottom: 1px;

      .primcol {
        float: left;
        width: 24px;
        height: 16px !important;
        margin-top: 5px;
        height: 100%;
        background-color: #000;
        border-radius: 2px;
      }

      .arrowimg {
        float: right;
        margin-top: 7px;
        width: 10px;
        height: 10px;
      }
    }

    p {
      font-size: 12px !important; 
      color: #212529;
    }
  }

  .rightbar {
    display: flex;
    align-items: center;

    .btn-duplicate {
      margin-right: 20px;
      cursor: pointer;
      text-align: center;
    }

    .btn-delete .arrow {
      width: 15px;
      height: 18px;
      cursor: pointer;
      margin: 4px;
    }
    .btn-delete{
      text-align: center;
    }
    .delete-btn-p{
      font-size: 11px;
    }
  }
  .strokeeff {
    display: none;
    margin-left: 10px;
  }

  .group {
    display: none;
  }

  .ungroup {
    display: none;
  }

  .colrsec {
    margin-right: 20px;
    text-align: center;
    color: #212529;
  }

  .colrsec svg{
    cursor: pointer;
    font-size: 20px;
    color: #000;
    margin: 4px;
  }

  .font-family-select{
    border-radius: 4px;
    border: 1px solid #aaa;
  }

  .dropdown-toggle {
    white-space: nowrap;
    width: 90px;
    font-size: 12px;
    background: transparent !important;
    color: #000 !important;
    border: 0 !important;
    padding: 0 0 18px;
    box-shadow: none !important;
  }

  .dropdown-toggle::after {
    margin-left: 10px;
  }

  .more-options.colrsec {
    display: flex;
    margin: 0;
    align-items: center;
    grid-gap: 0 12px;
  }

  .more-options.colrsec img {
    object-fit: contain;
    width: 24px;
    height: 24px;
  }

  .more-options.colrsec p {
    margin: 0;
    font-size: 12px;
    color: #212529;
  }

  .more-options svg {
    margin: 5px;
  }
  .delete-btn-p {
    font-size: 12px !important; 
    color: #212529;
    cursor: pointer;
  }  

  .bold{
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
  }

  .italic{
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
  }

  .more-options {
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.crop-outer{
  display: flex;
  gap: 20px;
  margin-bottom: 12px;
}

.crop-text{
  font-size: 12px;
  margin : 0;
  color: #212529;
  font-weight: 400;
}

.crop-done i,
.crop-cancel i {
  font-size: 24px;
}

.crop-done i{
  color : green
}

.crop-cancel i{
  color : red
}
.crop-done span,
.crop-cancel span {
  font-size: 12px;
}

.crop-done,
.crop-cancel{
  display: grid;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  .btn-duplicate{
    margin: 0 !important;
  }
}

.send-outer{
  display: flex;
  margin-bottom: 14px;
}
.send-back img,
.send-forward img{
  width: 20px !important;
  height: 20px !important;
}

.tool-text{
  font-size: 12px;
  margin: 0;
  color: #212529;
  cursor: pointer;
}

.font-family-dropdown.css-b62m3t-container,
.font-family-dropdown.css-3iigni-container{
  min-width: 220px !important;
}

.font-family-dropdown .css-13cymwt-control,
.font-family-dropdown .css-t3ipsp-control,
.font-family-dropdown .css-16xfy0z-control{
  min-height: 29px !important;
  height: 29px !important;
  cursor: pointer;
}

.font-family-dropdown .css-1fdsijx-ValueContainer,
.font-family-dropdown .css-1fdsijx-ValueContainer{
  height: 29px !important;
}

.font-family-dropdown .css-qbdosj-Input,
.font-family-dropdown .css-166bipr-Input,
.font-family-dropdown .css-1h01tm3-Input,
.font-family-dropdown .css-19bb58m{
  margin: 0 !important;
  padding: 0 !important;
}

.font-family-dropdown .css-1xc3v61-indicatorContainer,
.font-family-dropdown .css-15lsz6c-indicatorContainer,
.font-family-dropdown .css-1hb7zxy-IndicatorsContainer{
  padding: 0px 8px !important;
}

.font-family-dropdown .css-t3ipsp-control:hover{
  border-color: #ccc !important;
  box-shadow : none !important;
}

.font-size-dropdown .css-1xc3v61-indicatorContainer,
.font-size-dropdown .css-15lsz6c-indicatorContainer,
.font-size-dropdown .css-1hb7zxy-IndicatorsContainer{
  padding: 0 !important;
}

.font-size-dropdown .css-qbdosj-Input,
.font-size-dropdown .css-166bipr-Input,
.font-size-dropdown .css-1h01tm3-Input,
.font-size-dropdown .css-19bb58m{
  margin: 0 !important;
  padding: 0 !important;
}

.font-size-dropdown .css-13cymwt-control,
.font-size-dropdown .css-t3ipsp-control,
.font-size-dropdown .css-16xfy0z-control{
  min-height: 29px !important;
  cursor: pointer;
  max-height: 29px;
}

.lock-text,
.unlock-text{
  width: 40px;
}

.font-family-dropdown .css-1wy0on6,
.font-size-dropdown .css-1wy0on6{
  max-height: 29px;
}

.font-family-dropdown .css-hlgwow,
.font-size-dropdown .css-hlgwow{
  display: table;
  flex: none;
  max-height: 29px;
  line-height: normal;
}