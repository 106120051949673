// Reponsive Media Queries
$small: 568px;

@media screen and (min-width: 1100px) {
  .mobilenav {
    display: none !important;

  }
  .mobilePanel {
    display: none !important;
  }
  .largedevice {
    display: block !important;

  }
  .canvas-panel-header-toolbar {
    height: 100px;
  }
  .nav {
    height: 100px
  }
  .largeNavbar {
    min-height: 77px;
  }
}
@media screen and (min-width: 568px) {
  .mobilenav {
    display: none !important;
  }
  .mobilePanel {
    display: none !important;
  }

  .largedevice {
    display: block !important;
  }
}
@media screen and (max-width: 1100px) and (min-width:568px) {
  .nav {
    height: 150px !important;
  }
  .largedevice {
    display: block !important;
  }
  .mobilenav {
    display: none !important;
  }
  .mobilePanel {
    display: none !important;
  }
  .tabletzoom {
    display: block !important;
    position: fixed;
    top: 60%;
    left: 37%;
  }
  .largeNavbar {
    height: 150px;
  }
  .toolbar-container img {
    height: 15px !important;
    width: 15px !important;
  }
  .text-menu {
    font-size: 12px;
  }
}
@media screen and (min-width:$small) and (max-width:799px) {
  .mobilenav {
    display: none !important;
  }
  .largedevice {
    display: block !important;
  }
  .mobilePanel {
    display: none !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 1279px) {
  .temp-canvas {
    margin-left: 25%;
  }
}
@media screen and (max-width: 991px) {
  .logo-outer {
    height: 60px;
  }

  .logo-header-outer {
    top: 0;
    margin-top: 13px;
  }

  .logo-header-inner {
    width: 70px;
  }
  //preview
  .preview-image{
    grid-template-columns: none;
  }
  .preview-image-inner img{
    width: 100%;
  }
  .preview-img::before{
    border: none;
  }
  .preview-content button{
    margin-top: 80px;
  }
  //toolbar
  .font-family-dropdown.css-3iigni-container, .font-family-dropdown.css-b62m3t-container {
    min-width: 140px!important;
  }
  .toolbar-container .dropdown-toggle { 
    width: 70px; 
  }
  .toolbar-container {     
    z-index: 999;
    position: relative;
    top: 8px;
  }
  .dropdown-menu.show {
    display: block;
    max-height: 70vh;
    overflow-x: auto;
  }
}
@media screen and (min-width: 641px) {
  .mobile-side-bar{
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .mobile-side-bar {
    display: block;
  }
  .mobile-side-bar{
    display: flex;
    width: 50px;
    height: 40px;
    align-self: end;
    float: left;
    position: fixed;
    right: 25px;
    margin-top: 20px;
    bottom: 100px;
    top: calc(100% - 135px);
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 30px;
  }
  .mobilenav.fixed-bottom.mobilenav-active{
    position: fixed;
    top: 61px;
    background-color: #fff;
    height: 100vh;
  }
  .mobile-side-bar svg{
    fill: #00b5e2;
  }
  .text-muted{
    font-size: 20px;
  }
  .layers-icon{
    width: 20px;
    height: 20px;
  }
  .mobilePanel {
    display: block
  }
  .mobilenav {
    display: block !important;
  }
  .largedevice {
    display: none !important;
  }
  .menu {
    height: 70px;
    background-color: #F1F1F1;
  }
  .menu::-webkit-scrollbar {
    color: aqua;
  }
  .nav-item {
    color: black;
    background-color: #F1F1F1;
  }
  .zoombar {
    bottom: -25%;
  }
  .tabs {
    z-index: auto;
  }
  .menu {
    z-index: 300;
  }
  .selectedColor {
    background-color: #00b5e2;
    color: white;
  }
  .canvas-panel-header-toolbar {
    min-height: 77px;
  }
  .temp-canvas {
    margin-left: 0;
  }
  .side-nav {
    min-width: 56px;
    float: left;
    font-size: 12px;
    color: #697582;
    margin-top: 10px;
    text-align: center;
  }
  .bottom-outer{
    right: 100px;
  }
  //image gallery
  .mobilenav .img-title{
    background-color: #e5f6fd;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .mobilenav .img-editor{
    background-color: #fff;
    height: 100vh;
    min-width: 100%;
  }
  .mobilenav .img-upload{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobilenav .img-upload i{
    font-size: 40px;
  }
  .mobilenav p.img-text{
    padding: 18px;
  }
  .mobilenav .loader-image-preview.loader-active{
    top: 200px;
  }
  //text
  .mobilenav .text-editor{
    background-color: #fff;
    height: 100vh;
    min-width: 100%;
  }
  .mobilenav .dcl-upload-button{
    margin-bottom: 10px;
  }
  //color
  .mobilenav .color-gallery{
    min-height: 100vh;
    min-width: 100%;
  }
  .mobilenav .color-title{
    background-color: #e5f6fd;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .mobilenav .solid-colors .solidcolor {
    width: 40px;
    height: 40px;
    display: table;
    border-radius: 50%;
    margin: 5px;
    line-height: 34px;
    float: left;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
  }
  .mobilenav .solidcolor{
    width: 37px;
    height: 37px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    margin: 0 7px 14px;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
    position: relative;
  }
  .mobilenav .grdcolor{
    width: 37px;
    height: 37px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    margin: 0 7px 14px;
    box-shadow: 0 0 2px -1px #000;
    text-align: center;
    position: relative;
  }
  .mobilenav .gradients-colors{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobilenav .gradients-colors .grdcol1 {
    background-image: linear-gradient(#62ff00, yellow);
  }

  .mobilenav .gradients-colors .grdcol2 {
    background-image: linear-gradient(to right, red, yellow);
  }

  .mobilenav .gradients-colors.grdcol3 {
    background-image: linear-gradient(-90deg, #39d4cd, #ff9900);
  }

  .mobilenav .gradients-colors .grdcol4 {
    background-image: linear-gradient(to right, #FF1919, #fff);
  }
  .mobilenav .custom-color{
    padding: 16px;
  }
  .mobilenav .solid-colors{
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mobilenav .popover{
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  //clip
  .mobilenav .clip-gallery{
    background-color: #fff;
    min-height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .clipimage-container-outer
  {
    height: 300px !important;
  }
  //shapes
  .mobilenav .shape-gallery{
    background-color: #fff;
    height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .patterns{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 27px;
    justify-content: center;
  }
  .mobilenav .patterns.shapes-inner{
    position: relative;
    border: 1px solid #ccc;
    width: 100px;
    height: 100px;
    justify-content: center !important;
    align-items: center;
    padding: 0 !important;
    margin: 0;
  }
  //layers
  .mobilenav .layers-gallery{
    background-color: #fff;
    min-height: 100vh !important;
    min-width: 100%;
  }
  .mobilenav .layer-no-content{
    text-align: center;
    position: relative;
    height: 200px;
    padding-top: 0;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  .preview-wizard.preview-wizard-active{
    display: block;
    width: 100%;
  }
  .preview-wizard,
  .preview-wizard-receipt{
    display: none;
  }
  .preview-wizard::before{
    content: none;
  }
  .preview-wizard p{
    margin: 0;
    font-weight: bolder;
    text-align: center;
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tab-content{
    border: none !important;
  }
  .color-outer button{
    width: 100%;
  }
  .toolbar-container.container {
    margin-top: 0;
  }
  .nav-link{
    padding: 15px 1rem;
  }
  .toolbar-container {
    z-index: 999;
    position: relative;
    top: 0;
    margin: 0;
    width: 100% !important;
    left: 0;
    justify-content: space-between;
    border: 0;
    padding: 11px 10px 0 15px !important;
  }
  .logo-header-inner {
    width: 70px;
    top: 10px;
    left: 10px;
  }
  .logo-header-outer {
    top: 0;
    margin: 0;
  }
  .image.toolbar-container {
    justify-content: center;
  }
  .mobilenav .dcl-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #00b5e2;
    border-radius: 3px;
    padding: 8px 20px;
    background-color: #00b5e2;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } 
  .zoom-value{
    margin-right: 10px;
  }
  .zoom-range svg{
    margin-right: 10px;
  }
}
@media screen and (max-width: 480px) {
  .preview-qty-price-outer {
    grid-template-columns: 100%;
    grid-gap: 15px;
  } 
  .css-1dimb5e-singleValue {
    font-size: 13px;
  }
  .toolbar-container .colrsec .font-family-dropdown.css-b62m3t-container .css-1dimb5e-singleValue {
    width: 50px;
  }
  .css-1dimb5e-singleValue {
      font-size: 13px;
  }
  .font-family-dropdown.css-3iigni-container, .font-family-dropdown.css-b62m3t-container {
    min-width: 50px!important;
    width: 101px;
  }
  .toolbar-container .colrsec {
    margin-right: 7px!important;
  }
  .font-family-dropdown .css-1hb7zxy-IndicatorsContainer, .font-family-dropdown .css-1xc3v61-indicatorContainer, .font-family-dropdown .css-15lsz6c-indicatorContainer {
    padding: 0px!important;
  }
  .toolbar-container .dropdown-toggle {
      width: 49px;
  }
  .toolbar-container .leftbar .font-color-container {     
    margin-right: 2px 
  }
  .toolbar-container.container {
      padding: 11px 5px 0 10px !important;
  }
  .image.toolbar-container .colrsec {
    margin-right: 6px!important;
  }
  .image.toolbar-container p.tool-text {
      font-size: 11px;
  }
}
@media screen and (max-width: 425px) {
  .bottom-outer{
    right: 50px;
    width: 282px;
  }
  .mobile-side-bar{
    right: 0px;
  }
}
@media screen and (max-width: 335px) {
  .clr-btn{
    width: 50px;
  }
  .bottom-outer{
    width: 260px;
  }
}